<template>
  <div class="input-container" :class="{ error }">
    <label class="input-container__label" v-if="label" :for="uuid">
      {{ label }}
    </label>
    <input
      v-show="type !== 'hidden'"
      v-on="{
        input: updateModelValue,
        change: updateModelValue,
      }"
      ref="$input"
      :id="uuid"
      :disabled="disabled"
      :readonly="readOnly"
      v-maska
      :data-maska="dataMask"
      :data-maska-token="dataMaskTokens"
      :class="[
        'v-popper--error',
        'input-container__input-field styling',
        { 'input-container__input-field--error': error },
        { default: 'styling--default', none: 'styling--none' }[styling],
      ]"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      :autocomplete="options?.autocomplete"
      :aria-describedby="error ? `${uuid}-error` : undefined"
      :aria-invalid="!!error"
    />
    <base-icon
      :class="{ hidden: isEyeVisible }"
      v-if="type === 'password'"
      class="icon-eye"
      :icon="isPasswordVisible ? 'eye-slash' : 'eye'"
      @click="togglePasswordVisibility"
    />
    <transition name="fade-down">
      <BaseErrorMessage
        v-if="error"
        :id="`${uuid}-error`"
        class="error-container"
        :class="{ msg: error }"
      >
        {{ error }}
      </BaseErrorMessage>
    </transition>
  </div>
</template>

<script lang="ts" setup>
// import SetupFormComponent from '@/utils/SetupFormComponent'
import { v4 as createUid } from 'uuid';

/**
 * @docs
 * @url https://beholdr.github.io/maska/#/?id=support
 **/
// ************* PROPS ************* //
interface Props {
  label?: string;
  styling?: 'default' | 'none';
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  error?: string;
  dataMask?: string;
  options?: {
    autocomplete?:
      | 'current-password'
      | 'new-password'
      | 'useremail'
      | 'username'
      | 'email'
      | 'off';
  };
  dataMaskTokens?:
    | string
    | {
        pattern: string;
      };
  modelValue?: string | number;
  modelValueModifiers?: {
    lazy: string;
    trim: string;
  };
  type?: 'text' | 'email' | 'tel' | 'subject' | 'hidden' | 'password';
}

// ************* EMITS ************* //
const emit = defineEmits<{
  'update:modelValue': [string];
}>();

const props = withDefaults(defineProps<Props>(), {
  styling: 'default',
});

const $input = ref<HTMLInputElement>();
const isPasswordVisible = ref(false);
const uuid = createUid();

// ************* GETTERS ************* //
const isEyeVisible = computed(() => {
  return !props.modelValue?.length;
});

const updateModelValue = (e: Event) => {
  if (props.modelValueModifiers?.trim) {
    (e.target as HTMLInputElement).value.trim();
  }
  if (e.type === 'change' && props.modelValueModifiers?.lazy) {
    emit('update:modelValue', (e.target as HTMLInputElement).value);
  }
  if (e.type === 'input' && !props.modelValueModifiers?.lazy) {
    emit('update:modelValue', (e.target as HTMLInputElement).value);
  }
};
const focus = () => {
  $input.value?.focus();
};

const togglePasswordVisibility = () => {
  isPasswordVisible.value = !isPasswordVisible.value;
  $input.value!.type = isPasswordVisible.value ? 'text' : 'password';
};

defineExpose({
  focus,
});
</script>

<style lang="scss" scoped>
@mixin transform-up {
  font-size: $font-small;
  transform: translateY(-60%);
  top: 0;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;

  &.error {
    .input-container {
      &__label {
        color: var(--color-error);

        &::before {
          background-color: var(--color-black);
        }
      }
    }
  }

  &:focus-within {
    .input-container {
      &__label {
        @include transform-up;

        &::before {
          content: '';
          position: absolute;
          z-index: -1;
          height: 62%;
          width: 100%;
          top: 0;
          left: 0;
          background-color: var(--color-black);
        }
      }

      &__input-field {
        &::placeholder {
          visibility: visible;
        }
      }
    }
  }

  &__label {
    transition: $base-transition;
    position: absolute;
    top: 50%;
    border-radius: 0.5rem;
    left: 3rem;
    z-index: 5;
    font-size: $font-normal;
    transform: translateY(-50%);

    & ~ .input-container__input-field {
      &::placeholder {
        transition: visibility 0.2s ease-in-out;
        visibility: hidden;
      }
    }

    &:has(~ .input-container__input-field:not(:placeholder-shown)) {
      @include transform-up;
    }
  }

  &__input-field {
    border: none;
    border-radius: $border-radius-default;
    color: var(--color-white);
    padding: 2rem 4rem;
    font-size: $font-normal;
    outline: none;
    font-family: inherit;

    &:disabled {
      cursor: not-allowed;
      opacity: 50%;
    }

    &:not(:placeholder-shown) ~ .input-container__input-field {
      @include transform-up;
    }

    &.styling {
      &--default {
        background-color: var(--color-input-half);

        &:focus {
          outline: 2px solid var(--color-primary);
        }

        &::placeholder {
          color: var(--color-grey-800);
          font-family: inherit;
        }
      }

      &--none {
        background-color: transparent;
        margin-bottom: 0;
        padding: 0;
        border-radius: 0;
      }
    }

    &--error {
      border: 2px solid $color-error;
      color: var(--color-error);
      margin-bottom: 0rem;
    }

    &--error::placeholder {
      color: var(--color-error);
    }
  }

  .icon-eye {
    width: 2rem;
    height: 2rem;
    position: absolute;
    font-size: 1.6rem;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--color-white);
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &.hidden {
      opacity: 0;
    }
  }
}

//.field {
// &:-webkit-autofill,
// &:-webkit-autofill:hover,
// &:-webkit-autofill:focus,
// &:-webkit-autofill:active {
//   // -webkit-box-shadow: 0 0 0 30px white inset !important;
//   background-color: -internal-light-dark var(--color-input-half) !important;
// }
//}

.error-container {
  position: absolute;
  transform: translateY(100%);
  bottom: 0;
  left: 0;
  &.msg {
    margin-left: 2rem;
  }
}
</style>
